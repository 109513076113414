<template>
    <b-container class="my-5">
      <b-row>
        <b-col cols="12" md="8" lg="6" class="mx-auto">
          <b-card title="Upload Photos" class="shadow-sm">
            <b-form @submit.prevent="uploadPhotos">
              <b-form-group label="Select Photos">
                <b-form-file
                  @change="handleFiles"
                  multiple
                  accept="image/*"
                  class="mb-3"
                ></b-form-file>
              </b-form-group>
              <b-form-group label="Customer Email">
                <b-form-input
                  v-model="customerEmail"
                  type="email"
                  placeholder="Enter customer email"
                ></b-form-input>
              </b-form-group>
              <b-button type="submit" variant="primary" class="mr-2">Upload</b-button>
              <b-button @click="notifyCustomer" variant="success">Notify Customer</b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  
  <script>
  import { storage, auth } from '../firebase';
  import axios from 'axios';
  
  export default {
    data() {
      return {
        photos: [],
        customerEmail: '',
        uploadedPhotoURLs: [],
        user: null
      };
    },
    methods: {
      handleFiles(event) {
        this.photos = Array.from(event.target.files);
      },
      async uploadPhotos() {
        try {
          const uploadPromises = this.photos.map(async (photo) => {
            const storageRef = storage.ref();
            const photoRef = storageRef.child(`photos/${photo.name}`);
            await photoRef.put(photo);
            const downloadURL = await photoRef.getDownloadURL();
            return downloadURL;
          });
  
          this.uploadedPhotoURLs = await Promise.all(uploadPromises);
  
          alert('Photos uploaded successfully!');
        } catch (error) {
          console.error('Error uploading photos:', error);
          alert('Failed to upload photos.');
        }
      },
      async notifyCustomer() {
        try {
          const user = auth.currentUser;
          if (!user) {
            throw new Error('User is not authenticated');
          }
  
          const idToken = await user.getIdToken();
          const notificationData = {
            email: this.customerEmail,
            photoURLs: this.uploadedPhotoURLs
          };
  
          await axios.post('https://us-central1-photos-d07c0.cloudfunctions.net/sendNotification', notificationData, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          });
  
          alert('Customer notified successfully!');
        } catch (error) {
          console.error('Error notifying customer:', error);
          alert('Failed to notify customer.');
        }
      }
    },
    created() {
      auth.onAuthStateChanged(user => {
        this.user = user;
      });
    }
  }
  </script>
  
  <style scoped>
  /* Add any additional custom styles here */
  </style>
  