<template>
    <div class="p-4 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h2 class="text-2xl font-semibold text-gray-700">Sign Up</h2>
      <b-form @submit.prevent="signUp">
        <b-form-group label="Email">
          <b-form-input
            v-model="email"
            type="email"
            placeholder="Enter email"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Password">
          <b-form-input
            v-model="password"
            type="password"
            placeholder="Enter password"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Sign Up</b-button>
      </b-form>
      <p v-if="errorMessage" class="text-red-500">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script>
  import { auth } from '../firebase';
  
  export default {
    data() {
      return {
        email: '',
        password: '',
        errorMessage: ''
      };
    },
    methods: {
      async signUp() {
        try {
          await auth.createUserWithEmailAndPassword(this.email, this.password);
          this.$router.push('/upload');
        } catch (error) {
          this.errorMessage = error.message;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  