import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
apiKey: "AIzaSyBAQXyrpZE6l3umOD_xLU2PsceOmraPfmE",
  authDomain: "photos-d07c0.firebaseapp.com",
  projectId: "photos-d07c0",
  storageBucket: "photos-d07c0.appspot.com",
  messagingSenderId: "114809125543",
  appId: "1:114809125543:web:4bb159e090e6de28287982",
  measurementId: "G-XGNX3C0FEY"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

export { auth, db, storage };
