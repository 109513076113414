import Vue from 'vue';
import Router from 'vue-router';
import PhotoUpload from '../components/PhotoUpload.vue';
import ViewPhotos from '../components/ViewPhotos.vue';
import SignUp from '../components/SignUp.vue';
import Login from '../components/Login.vue';
import { auth } from '../firebase';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/upload',
      name: 'PhotoUpload',
      component: PhotoUpload,
      meta: { requiresAuth: true }
    },
    {
      path: '/view/:id',
      name: 'ViewPhotos',
      component: ViewPhotos
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '*',
      redirect: '/login'
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) {
    next('/login');
  } else {
    next();
  }
});

export default router;
